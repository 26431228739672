<template>
  <v-menu
    v-model="menu"
    menu-props="offsetY"
    :nudge-bottom="35"
    max-width="600"
    min-width="600"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on: menu }">
      <ToolbarIconButton
        :tooltip="'Сортировка'"
        :icon="'mdi-sort-variant'"
        data-test="sortButton"
        v-on="{ ...menu }"
      />
    </template>

    <v-card>
      <v-card-title class="px-3 pt-3 pb-0">
        <h2 class="title">
          Сортировка
        </h2>
      </v-card-title>
      <v-card-text>
        <v-layout>
          <v-flex xs6>
            <h3 class="subheading grey--text text--darken-1">
              Доступно
            </h3>
            <div class="list">
              <v-list>
                <v-list-item
                  v-for="(item, name) in availableColumns"
                  :key="name"
                  dense
                  @click="moveToSelectedColumns(item)"
                >
                  {{ item.text }}
                </v-list-item>
              </v-list>
            </div>
          </v-flex>

          <v-divider
            vertical
            class="mx-1"
          />

          <v-flex xs6>
            <h3 class="subheading grey--text text--darken-1 pl-3">
              Выбрано
            </h3>
            <div class="list pl-3">
              <v-list>
                <v-list-item
                  v-for="(item, name) in selectedColumns"
                  :key="name"
                  class="list-title"
                  dense
                >
                  <v-list-item-action>
                    <v-layout>
                      <v-icon
                        small
                        @click="moveUp(item)"
                      >
                        mdi-arrow-up
                      </v-icon>
                      <v-icon
                        small
                        @click="moveDown(item)"
                      >
                        mdi-arrow-down
                      </v-icon>
                    </v-layout>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      @click="moveToAvailableColumns(item)"
                      v-text="item.text"
                    />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      small
                      icon
                      @click="changeDirection(item)"
                    >
                      <v-icon
                        small
                        color="grey darken-1"
                      >
                        {{ item.isDesc === false ? 'mdi-format-text-rotation-vertical' : 'mdi-format-text-rotation-up' }}
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          text
          color="primary"
          @click="apply"
        >
          Применить
        </v-btn>
        <v-btn
          small
          text
          @click="menu = !menu"
        >
          Отмена
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { arrayMoveImmutable } from "array-move";
import ToolbarIconButton from "./ToolbarIconButton.vue";

export default {
  components: {
    ToolbarIconButton,
  },
  props: ["store", "columns"],
  data() {
    return {
      menu: false,
      selectedColumns: [],
    };
  },
  computed: {
    availableColumns() {
      return this.columns
        .filter((c) => c.sortable === true)
        .filter(
          ({ name }) => !this.selectedColumns.find((s) => s.name === name),
        )
        .map((el) => {
          return {
            name: el.name,
            text: el.text,
          };
        });
    },
    storeSort: {
      get() {
        return this.$store.getters[`${this.store}/sort`];
      },
      set() {
        return this.$store.commit(
          `${this.store}/setSort`,
          this.copyColumns(this.selectedColumns),
        );
      },
    },
  },
  watch: {
    menu(value) {
      if (value) {
        this.selectedColumns = this.copyColumns(this.storeSort);
      } else {
        this.selectedColumns = [];
      }
    },
  },
  methods: {
    copyColumns(source) {
      return source.map((s) => {
        return {
          name: s.name,
          text: s.text,
          isDesc: s.isDesc,
        };
      });
    },
    apply() {
      this.menu = false;
      this.storeSort = this.copyColumns(this.selectedColumns);
    },
    moveUp(item) {
      const from = this.selectedColumns.indexOf(item);
      this.selectedColumns = arrayMoveImmutable(this.selectedColumns, from, from - 1);
    },
    moveDown(item) {
      const from = this.selectedColumns.indexOf(item);
      this.selectedColumns = arrayMoveImmutable(this.selectedColumns, from, from + 1);
    },
    moveToSelectedColumns(item) {
      if (!item) {
        return;
      }
      this.selectedColumns.push({
        name: item.name,
        text: item.text,
        isDesc: false,
      });
    },
    moveToAvailableColumns(item) {
      if (!item) {
        return;
      }
      const index = this.selectedColumns.map((s) => s.name).indexOf(item.name);
      this.selectedColumns.splice(index, 1);
    },
    changeDirection(item) {
      item.isDesc = item.isDesc === false ? true : false;
    },
  },
};
</script>
