var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-1 mb-1",attrs:{"tile":""}},[_c('v-card-title',{staticClass:"pa-2"},[_c('v-row',[_c('h1',{staticClass:"title my-3 mx-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(!_vm.disableAdding)?_c('v-divider',{staticClass:"ma-2",attrs:{"inset":"","vertical":""}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.disableAdding)?_c('v-btn',_vm._g({staticClass:"mt-3 mx-2",attrs:{"color":"primary","to":_vm.addRoute ? _vm.addRoute : { path: ("/" + _vm.resource + "/0") },"dark":"","small":""}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Добавить")])]),(!_vm.disableSort)?_c('DataSort',{staticClass:"my-3 mx-2",attrs:{"store":_vm.store,"columns":_vm.visibleColumns}}):_vm._e(),(!_vm.disableFilter)?_c('DataFilter',{staticClass:"my-3 mx-2",attrs:{"store":_vm.store,"disabled":_vm.loading}}):_vm._e(),(!_vm.disableFilter)?_c('FilterView',{attrs:{"store":_vm.store,"disabled":_vm.loading}}):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.resource === 'shippings')?_c('v-btn',_vm._g({staticClass:"my-3 mx-2",attrs:{"small":"","icon":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.changeCompactMode()}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.compactMode ? "mdi-magnify-plus-outline" : "mdi-magnify-minus-outline"))])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.compactMode ? "Развернутый режим" : "Сокращенный режим"))])])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mb-1 table-striped",attrs:{"items":_vm.items,"headers":_vm.visibleColumns,"loading":_vm.loading,"items-per-page":_vm.rowsCount,"hide-default-footer":"","hide-default-header":"","dense":_vm.compactMode ? true : false,"no-data-text":"Нет данных"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((_vm.visibleColumns),function(header){return _c('td',{key:header.name,on:{"dblclick":function($event){_vm.$router
              .push(
                _vm.rowTo
                  ? {
                    name: _vm.rowTo,
                    params: { id: item.id, isDraft: _vm.allowDelete }
                  }
                  : { path: ("/" + _vm.resource + "/" + (item.id)) }
              )
              .catch(function (err) {})}}},[(_vm.isDate(header.name) || header.type === 'date')?_c('div',{staticClass:"cell-dense-height cell-align-center"},[_vm._v(" "+_vm._s(item[header.name] ? _vm.moment(item[header.name]).format("DD.MM.YY") : "")+" ")]):(header.type === 'array')?_c('div',{staticClass:"cell-dense-height cell-align-center"},[(_vm.showTooltip(_vm.getArrayText(item[header.name]), header.maxLength))?_c('TextCell',{attrs:{"text":_vm.getArrayText(item[header.name]),"max-length":header.maxLength}}):_c('span',{style:(_vm.getCropTextCell(header))},[_vm._v(" "+_vm._s(item[header.name] && item[header.name].length > 0 ? item[header.name].join("; ") : "")+" ")])],1):(header.type === 'link')?_c('a',{on:{"dblclick":function($event){_vm.$router
                .push({ name: 'shipping', params: { id: item.id} })
                .catch(function (err) {})}}},[_vm._v(" "+_vm._s(item[header.name])+" ")]):(header.name === 'arrivalFactory')?_c('div',{staticClass:"cell-dense-height cell-align-center"},[(_vm.showTooltip(_vm.getArrivalFactory(item[header.name]), header.maxLength))?_c('TextCell',{attrs:{"text":_vm.getArrivalFactory(item[header.name]),"max-length":header.maxLength}}):_c('span',{style:(_vm.getCropTextCell(header))},[_vm._v(" "+_vm._s(_vm.getArrivalFactory(item[header.name]))+" ")])],1):(header.type === 'planFactDates')?_c('div',{staticClass:"cell-dense-height cell-align-center"},[_c('PlanFactDates',{attrs:{"plan":item[header.name] ? item[header.name].plan : null,"fact":item[header.name] ? item[header.name].fact : null}})],1):(
              header.maxLength &&
                item[header.name] &&
                item[header.name].length >= header.maxLength
            )?_c('TextCell',{attrs:{"text":item[header.name],"max-length":header.maxLength}}):(header.type === 'object' && item[header.name])?_c('div',{staticClass:"cell-dense-height cell-align-center"},[(_vm.showTooltip(item[header.name].title, header.maxLength))?_c('TextCell',{attrs:{"text":item[header.name].title,"max-length":header.maxLength}}):_c('span',{style:(_vm.getCropTextCell(header))},[_vm._v(" "+_vm._s(item[header.name].title && item[header.name].title.length ? item[header.name].title : "")+" ")])],1):(header.name === 'manager' && _vm.resource === 'shipping-drafts')?_c('div',{staticClass:"cell-dense-height cell-align-center"},[_c('span',{style:(_vm.getCropTextCell(header))},[_vm._v(" "+_vm._s(item[header.name] ? item[header.name].title : "")+" ")])]):(header.type === 'dateArray')?_c('div',{staticClass:"cell-dense-height cell-align-center"},[_c('span',{style:(_vm.getCropTextCell(header))},[_vm._v(" "+_vm._s(item[header.name] && item[header.name].length > 0 ? _vm.moment(item[header.name]).format("DD.MM.YY").join("; ") : "")+" ")])]):(header.type === 'action')?_c('div',{staticClass:"cell-dense-height cell-align-center px-1"},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'goodsDetail', params: { id: item.id } })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-format-align-justify ")])],1)]}}],null,true)},[_c('span',[_vm._v("Описание товаров")])])],1),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'attachment', params: { id: item.id } })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-document-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Документы")])])],1),_c('v-flex',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'messages', params: { id: item.id, number: item.number } })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-email-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Сообщения")])])],1)],1)],1):_c('div',{staticClass:"cell-dense-height cell-align-center"},[_c('span',{style:(_vm.getCropTextCell(header))},[_vm._v(" "+_vm._s(item[header.name])+" ")])])],1)}),0)]}}])},[_c('template',{slot:"header"},[_c('thead',[_c('tr',_vm._l((_vm.visibleColumns),function(header){return _c('th',{key:header.name,staticClass:"pl-3 pr-0 py-1",style:({
              minWidth: header.width ? ((header.width) + "px") : 'auto',
              whiteSpace: header.width ? 'normal' : 'nowrap'
            })},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)])])],2),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.pages,"total-visible":10,"color":"primary"},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }