<template>
  <div>
    <v-card
      tile
      class="elevation-1 mb-1"
    >
      <v-card-title class="pa-2">
        <v-row>
          <h1 class="title my-3 mx-2">
            {{ title }}
          </h1>
          <v-divider
            v-if="!disableAdding"
            class="ma-2"
            inset
            vertical
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="!disableAdding"
                color="primary"
                :to="addRoute ? addRoute : { path: `/${resource}/0` }"
                dark
                small
                class="mt-3 mx-2"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Добавить</span>
          </v-tooltip>

          <DataSort
            v-if="!disableSort"
            :store="store"
            :columns="visibleColumns"
            class="my-3 mx-2"
          />

          <DataFilter
            v-if="!disableFilter"
            :store="store"
            :disabled="loading"
            class="my-3 mx-2"
          />

          <FilterView
            v-if="!disableFilter"
            :store="store"
            :disabled="loading"
          />


          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="resource === 'shippings'"
                class="my-3 mx-2"
                small
                icon
                color="primary"
                :disabled="loading"
                v-on="on"
                @click="changeCompactMode()"
              >
                <v-icon>{{ compactMode ? "mdi-magnify-plus-outline" : "mdi-magnify-minus-outline" }}</v-icon>
              </v-btn>
            </template>
            <span>{{ compactMode ? "Развернутый режим" : "Сокращенный режим" }}</span>
          </v-tooltip>
        </v-row>
      </v-card-title>
    </v-card>
    <v-data-table
      :items="items"
      :headers="visibleColumns"
      :loading="loading"
      :items-per-page="rowsCount"
      hide-default-footer
      hide-default-header
      :dense="compactMode ? true : false"
      class="elevation-1 mb-1 table-striped"
      no-data-text="Нет данных"
    >
      <template
        slot="header"
      >
        <thead>
          <tr>
            <th
              v-for="header in visibleColumns"
              :key="header.name"
              class="pl-3 pr-0 py-1"
              :style="{
                minWidth: header.width ? `${header.width}px` : 'auto',
                whiteSpace: header.width ? 'normal' : 'nowrap'
              }"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td
            v-for="header in visibleColumns"
            :key="header.name"
            @dblclick="
              $router
                .push(
                  rowTo
                    ? {
                      name: rowTo,
                      params: { id: item.id, isDraft: allowDelete }
                    }
                    : { path: `/${resource}/${item.id}` }
                )
                .catch(err => {})
            "
          >
            <div
              v-if="isDate(header.name) || header.type === 'date'"
              class="cell-dense-height cell-align-center"
            >
              {{
                item[header.name]
                  ? moment(item[header.name]).format("DD.MM.YY")
                  : ""
              }}
            </div>
            <div
              v-else-if="header.type === 'array'"
              class="cell-dense-height cell-align-center"
            >
              <TextCell
                v-if="showTooltip(getArrayText(item[header.name]), header.maxLength)"
                :text="getArrayText(item[header.name])"
                :max-length="header.maxLength"
              />
              <span
                v-else
                :style="getCropTextCell(header)"
              >
                {{
                  item[header.name] && item[header.name].length > 0
                    ? item[header.name].join("; ")
                    : ""
                }}
              </span>
            </div>
            <a
              v-else-if="header.type === 'link'"
              @dblclick="
                $router
                  .push({ name: 'shipping', params: { id: item.id} })
                  .catch(err => {})
              "
            >
              {{ item[header.name] }}
            </a>
            <div
              v-else-if="header.name === 'arrivalFactory'"
              class="cell-dense-height cell-align-center"
            >
              <TextCell
                v-if="showTooltip(getArrivalFactory(item[header.name]), header.maxLength)"
                :text="getArrivalFactory(item[header.name])"
                :max-length="header.maxLength"
              />
              <span
                v-else
                :style="getCropTextCell(header)"
              >
                {{
                  getArrivalFactory(item[header.name])
                }}
              </span>
            </div>
            <div
              v-else-if="header.type === 'planFactDates'"
              class="cell-dense-height cell-align-center"
            >
              <PlanFactDates
                :plan="
                  item[header.name] ? item[header.name].plan : null
                "
                :fact="
                  item[header.name] ? item[header.name].fact : null
                "
              />
            </div>
            <TextCell
              v-else-if="
                header.maxLength &&
                  item[header.name] &&
                  item[header.name].length >= header.maxLength
              "
              :text="item[header.name]"
              :max-length="header.maxLength"
            />
            <div
              v-else-if="header.type === 'object' && item[header.name]"
              class="cell-dense-height cell-align-center"
            >
              <TextCell
                v-if="showTooltip(item[header.name].title, header.maxLength)"
                :text="item[header.name].title"
                :max-length="header.maxLength"
              />
              <span
                v-else
                :style="getCropTextCell(header)"
              >
                {{
                  item[header.name].title && item[header.name].title.length
                    ? item[header.name].title
                    : ""

                }}
              </span>
            </div>
            <div
              v-else-if="header.name === 'manager' && resource === 'shipping-drafts'"
              class="cell-dense-height cell-align-center"
            >
              <span
                :style="getCropTextCell(header)"
              >
                {{
                  item[header.name]
                    ? item[header.name].title
                    : ""
                }}
              </span>
            </div>
            <div
              v-else-if="header.type === 'dateArray'"
              class="cell-dense-height cell-align-center"
            >
              <span
                :style="getCropTextCell(header)"
              >
                {{
                  item[header.name] && item[header.name].length > 0
                    ? moment(item[header.name]).format("DD.MM.YY").join("; ")
                    : ""
                }}
              </span>
            </div>
            <div
              v-else-if="header.type === 'action'"
              class="cell-dense-height cell-align-center px-1"
            >
              <v-layout row>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        color="primary"
                        v-bind="attrs"
                        @click="$router.push({ name: 'goodsDetail', params: { id: item.id } })"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-format-align-justify
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Описание товаров</span>
                  </v-tooltip>
                </v-flex>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        color="primary"
                        v-bind="attrs"
                        @click="$router.push({ name: 'attachment', params: { id: item.id } })"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-file-document-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Документы</span>
                  </v-tooltip>
                </v-flex>
                <v-flex>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        color="primary"
                        v-bind="attrs"
                        @click="$router.push({ name: 'messages', params: { id: item.id, number: item.number } })"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-email-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Сообщения</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </div>
            <div
              v-else
              class="cell-dense-height cell-align-center"
            >
              <span :style="getCropTextCell(header)">
                {{ item[header.name] }}
              </span>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        :total-visible="10"
        color="primary"
      />
    </div>
  </div>
</template>

<script>
import * as dataListApi from "../backend/dataListApi.js";
import { saveDataListState } from "../helpers/dataListState.js";
import { createUrl } from "../helpers/urlQueryHelper.js";
import PlanFactDates from "../components/cells/PlanFactDates.vue";
import DataSort from "./DataSort.vue";
import DataFilter from "./DataFilter.vue";
import TextCell from "./cells/CropTextCell.vue";
import FilterView from "./FilterView.vue";

export default {
  components: {
    PlanFactDates,
    DataSort,
    DataFilter,
    TextCell,
    FilterView,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    disableAdding: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    addRoute: {
      type: Object,
      default: null,
      required: false,
    },
    resource: {
      type: String,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: false,
      required: false,
    },
    compactHeaders: {
      type: Array,
      default: () => [],
    },
    store: {
      type: String,
      default: null,
    },
    filterOptions: {
      type: Object,
      required: false,
      default: () => {},
    },
    rowTo: {
      type: String,
      required: false,
      default: null,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    disableFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalItems: null,
      sort: [],
      compactMode: false,
    };
  },
  computed: {
    pages() {
      if (
        !this.pagination ||
        this.pagination.rowsPerPage === null ||
        this.totalItems === null
      ) {
        return 0;
      }

      return Math.ceil(this.totalItems / this.pagination.rowsPerPage);
    },
    items() {
      return this.$store.getters[`${this.store}/items`];
    },
    pagination: {
      get() {
        return this.$store.getters[`${this.store}/pagination`];
      },
      set(pagination) {
        this.$store.commit(`${this.store}/setPagination`, pagination);
      },
    },
    rowsCount() {
      if (this.compactMode) {
        return Math.floor((window.innerHeight - 310) / 35);
      } else {
        return Math.floor((window.innerHeight - 350) / 45);
      }
    },
    columns() {
      if (this.compactMode) {
        return this.compactHeaders;
      } else {
        return this.headers;
      }
    },
    saveDataListState() {
      return saveDataListState;
    },
    visibleColumns() {
      const visibleColumns = this.columns.filter(
        c => !Object.prototype.hasOwnProperty.call(c, "visible") || c.visible,
      );
      visibleColumns.sort((a, b) => {
        let aOrder = 0;
        if (Object.prototype.hasOwnProperty.call(a, "order")) {
          aOrder = a.order;
        } else {
          aOrder = this.columns.indexOf(a);
        }
        let bOrder = 0;
        if (Object.prototype.hasOwnProperty.call(b, "order")) {
          bOrder = b.order;
        } else {
          bOrder = this.columns.indexOf(b);
        }
        if (aOrder < bOrder) {
          return -1;
        }
        if (aOrder > bOrder) {
          return 1;
        }
        return 0;
      });
      return visibleColumns;
    },
    dataListSort: {
      get() {
        return this.$store.getters[`${this.store}/sort`];
      },
      set(sort) {
        return this.$store.commit(`${this.store}/setSort`, sort);
      },
    },
    filters: {
      get() {
        return this.$store.getters[`${this.store}/filters`];
      },
      set(filters) {
        this.$store.commit(`${this.store}/setFilters`, filters);
      },
    },
    dataListFilter: {
      get() {
        return this.$store.getters[`${this.store}/filter`];
      },
      set(filter) {
        this.$store.commit(`${this.store}/setFilter`, filter);
      },
    },
    loading: {
      get() {
        return this.$store.getters[`${this.store}/loading`];
      },
      set(loading) {
        this.$store.commit(`${this.store}/setLoading`, loading);
      },
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.$store.getters[`${this.store}/allActionsRegistered`]) {
          this.getItems();
          this.$store.commit(`${this.store}/registerAction`, "pagging");
          if (
            this.pagination &&
            this.$router.currentRoute.query.page != this.pagination.page
          ) {

            this.saveDataListState(
              this.pagination.page,
              this.pagination.rowsPerPage,
              this.dataListFilter,
              this.dataListSort,
            );
          }
        }
      },
      deep: true,
    },
    dataListSort: {
      handler() {
        this.saveDataListState(
          1,
          this.rowsPerPage,
          this.dataListFilter,
          this.dataListSort,
        );
        this.getItems();
      },
      deep: true,
    },
    dataListFilter: {
      handler() {
        this.$store.commit(`${this.store}/registerAction`, "filter");
        this.saveDataListState(
          1,
          this.pagination.rowsPerPage,
          this.dataListFilter,
          this.dataListSort,
        );
        this.getItems();
      },
      deep: true,
    },
    rowsCount: {
      handler() {
        this.$store.commit(`${this.store}/setPagination`, {
          descending: this.pagination.descending,
          page: this.pagination.page,
          rowsPerPage: this.rowsCount,
          sortBy: this.pagination.sortBy,
          totalItems: this.pagination.totalItems,
        });
        this.$store.commit(`${this.store}/registerAction`, "rowsCount");
      },
      deep: true,
      immediate: true,
    },
    storeColumns: {
      handler() {
        this.saveDataListState(
          this.pagination.page,
          this.pagination.rowsPerPage,
          this.dataListFilter,
          this.dataListSort,
        );
        this.pagination = {
          descending: this.pagination.descending,
          page: this.pagination.page,
          rowsPerPage: this.rowsCount,
          sortBy: this.pagination.sortBy,
          totalItems: this.pagination.totalItems,
        };
        this.$store.commit(`${this.store}/registerAction`, "settings");
      },
      deep: true,
    },
  },
  mounted() {
    if (localStorage.getItem("page")) {
      try {
        const pagination = {
          descending: this.pagination.descending,
          page: JSON.parse(localStorage.getItem("page")),
          rowsPerPage: this.rowsCount,
          sortBy: this.pagination.sortBy,
          totalItems: this.pagination.totalItems,
        };
        this.$store.commit(`${this.store}/setPagination`, pagination);
      } catch (e) {
        localStorage.removeItem("pagination");
      }
    }
    if (localStorage.getItem("sort")) {
      try {
        this.$store.commit(
          `${this.store}/setSort`,
          JSON.parse(localStorage.getItem("sort")),
        );
      } catch (e) {
        localStorage.removeItem("sort");
      }
    }

  },
  created() {
    if (this.filterOptions && !this.dataListFilter) {
      const filter = {};
      for (const prop in this.filterOptions) {
        filter[prop] = { ...this.filterOptions[prop] };
        if (prop.type === "decimal" || prop.type === "int") {
          filter[prop].valueFrom = null;
          filter[prop].valueTo = null;
        } else {
          filter[prop].value = null;
        }
      }
      this.dataListFilter = filter;
      this.$store.commit(`${this.store}/setDefaultFilter`, filter);
    }
  },
  methods: {
    isDate(headerName) {
      var reg = /date+$/i;
      return reg.test(headerName);
    },
    async getItemsFromApi() {
      this.$store.commit(`${this.store}/setLoading`, true);
      const url = createUrl(
        this.pagination.page,
        this.pagination.rowsPerPage,
        this.dataListFilter,
        this.dataListSort,
      );

      const res = await dataListApi.getItems(this.resource, url);
      const items = res.items.map(i => {
        const item = { id: i.id };
        this.visibleColumns.forEach(c => {
          if (c.dataPath) {
            const dataPathSegments = c.dataPath.split(".");
            let value = i;
            dataPathSegments.forEach(s => (value ? (value = value[s]) : ""));
            item[c.name] = value;
          } else if (c.valueFn) {
            item[c.name] = c.valueFn(i);
          } else {
            if (Object.prototype.hasOwnProperty.call(i, c.name)) {
              item[c.name] = i[c.name];
            }
          }
        });
        return item;
      });

      this.$store.commit(`${this.store}/setLoading`, false);
      return { items, total: res.totalItems };
    },
    getItems() {
      return new Promise((resolve, reject) => {
        this.getItemsFromApi()
          .then(data => {
            this.$store.commit(`${this.store}/setItems`, data.items);
            this.totalItems = data.total;
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    changeCompactMode() {
      this.compactMode = !this.compactMode;
    },
    getCropTextCell(header) {
      return `min-width: ${header.width ? `${header.width}px` : "auto"}; 
      width: ${header.width ? `${header.width}px` : "auto"}; 
      white-space: ${header.width ? "nowrap" : "normal"}; 
      ${header.width ? "text-overflow: ellipsis" : ""};
      overflow:  ${header.width ? "hidden" : "visible"}`;
    },
    showTooltip(item, maxLength) {
      if (!item) {
        return;
      }
      if (item.length > maxLength) {
        return true;
      }
      if (item.length < maxLength) {
        return false;
      }
    },
    getArrayText(item) {
      if (item) {
        return item.join("; ");
      }
    },
    getArrivalFactory(item) {
      if (item.vehicle && item.arrivalFactoryDate) {
        return `${this.moment(item.arrivalFactoryDate).format("DD.MM.YY")} ${item.vehicle.title}`;
      }
      if (item.arrivalFactoryDate && !item.vehicle){
        return this.moment(item.arrivalFactoryDate).format("DD.MM.YY");
      }
      if (item.vehicle && !item.arrivalFactoryDate) {
        return item.vehicle.title;
      }
      return "";
    },
  },
};
</script>

<style scoped>
.cell-fixed-height {
  max-height: 50px;
  height: 50px;
  overflow: hidden;
}
.cell-align-center {
  display: flex;
  align-items: center;
}
.cell-dense-height {
  max-height: 35px;
  height: 35px;
  overflow: hidden;
}
</style>