<template>
  <div>
    <span
      v-if="fact && !plan"
      class="font-weight-bold"
    >
      {{ moment(fact).format("DD.MM.YY") }}
    </span>
    <span
      v-else-if="plan && !fact"
      class="grey--text"
    >
      {{ moment(plan).format("DD.MM.YY") }}
    </span>
    <span
      v-else
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span
            class="font-weight-bold"
            v-on="on"
          >{{ moment(fact).format("DD.MM.YY") }}</span>
        </template>
        <span>{{ moment(plan).format("DD.MM.YY") }}</span>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
export default {
  props: ["plan", "fact"],
};
</script>
