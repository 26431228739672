<template>
  <v-menu
    v-model="filterMenu"
    :disabled="disabled"
    bottom
    :nudge-bottom="35"
    :min-width="filtersCount <= 10 ? 650 : 1000"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on: menu }">
      <ToolbarIconButton
        :icon="'mdi-filter-variant'"
        :tooltip="'Фильтр'"
        data-test="filterButton"
        v-on="{ ...menu }"
      />
    </template>

    <v-card>
      <v-card-title class="py-0">
        <v-layout column>
          <v-flex>
            <h2 class="title my-3">
              Фильтр
            </h2>
          </v-flex>
        </v-layout>
      </v-card-title>

      <v-card-text>
        <v-layout
          row
          wrap
          align-end
        >
          <v-flex
            v-for="(item, index) in filter"
            :key="index"
            :xs12="filtersCount <= 10 ? true : false"
            :xs6="filtersCount > 10 ? true : false"
            :class="{ 'pr-1': filtersCount > 10, 'pl-1': filtersCount > 10 }"
            class="my-1"
          >
            <v-text-field
              v-if="item.type === 'text'"
              v-model="item.value"
              :label="item.title"
              hide-details
              outlined
              dense
              @change="isChanged = true"
            />

            <v-text-field
              v-if="item.type === 'phone'"
              v-model="item.value"
              :label="item.title"
              mask="+##(###)###-##-##"
              hide-details
              outlined
              dense
              @change="isChanged = true"
            />

            <MultipleReferenceCompact
              v-if="item.type === 'reference'"
              v-model="item.value"
              :label="item.title"
              :resource="item.resource"
              :disable-add="true"
              @input="isChanged = true"
            />

            <v-layout v-if="item.type === 'date'">
              <v-flex
                xs6
                class="pr-1"
              >
                <app-date-picker
                  v-model="item.valueFrom"
                  :label="item.title + ' c'"
                  :without-initial-data="true"
                  :incoming-class="'mb-0'"
                  :is-manual-edit="item.isManualEdit"
                  :clearable="true"
                  @input="isChanged = true"
                />
              </v-flex>
              <v-flex
                xs6
                class="pl-1"
              >
                <app-date-picker
                  v-model="item.valueTo"
                  :label="item.title + ' по'"
                  :without-initial-data="true"
                  :incoming-class="'mb-0'"
                  :is-manual-edit="item.isManualEdit"
                  :clearable="true"
                  @input="isChanged = true"
                />
              </v-flex>
            </v-layout>

            <MultipleEnumSelect
              v-if="item.type === 'enum'"
              v-model="item.value"
              :label="item.title"
              :resource="item.resource"
              @input="isChanged = true"
            />

            <v-layout v-if="item.type === 'decimal'">
              <v-flex
                xs6
                class="pr-1"
              >
                <v-text-field
                  v-model="item.valueFrom"
                  :label="item.title + ' c'"
                  hide-details
                  outlined
                  dense
                  @change="isChanged = true"
                />
              </v-flex>
              <v-flex
                xs6
                class="pl-1"
              >
                <v-text-field
                  v-model="item.valueTo"
                  :label="item.title + ' по'"
                  hide-details
                  outlined
                  dense
                  @change="isChanged = true"
                />
              </v-flex>
            </v-layout>

            <v-layout v-if="item.type === 'int'">
              <v-flex
                xs6
                class="pr-1"
              >
                <v-text-field
                  v-model="item.valueFrom"
                  :label="item.title + ' c'"
                  hide-details
                  outlined
                  dense
                  @change="isChanged = true"
                />
              </v-flex>
              <v-flex
                xs6
                class="pl-1"
              >
                <v-text-field
                  v-model="item.valueTo"
                  :label="item.title + ' по'"
                  hide-details
                  outlined
                  dense
                  @change="isChanged = true"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          text
          color="primary"
          @click="apply"
        >
          Применить
        </v-btn>
        <v-btn
          small
          text
          @click="filterMenu = !filterMenu"
        >
          Отмена
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import MultipleReferenceCompact from "./MultipleReferenceCompact.vue";
import MultipleEnumSelect from "./MultipleEnumSelect.vue";
import ToolbarIconButton from "./ToolbarIconButton.vue";
import { userRoles } from "../enums/user-roles.js";
import auth from "../auth";

export default {
  components: {
    ToolbarIconButton,
    MultipleReferenceCompact,
    MultipleEnumSelect,
  },
  props: ["store", "disabled"],
  data() {
    return {
      filterMenu: false,
      filter: null,
      isChanged: false,
      selected: null,
      filterTitle: "",
    };
  },
  computed: {
    quickFilter() {
      return this.$store.getters[`${this.store}/quickFilter`];
    },
    filtersCount() {
      if (this.filter) {
        return Object.keys(this.filter).length;
      } else {
        return 0;
      }
    },
    filters() {
      return this.$store.getters[`${this.store}/filters`];
    },
    storeFilter: {
      get() {
        return this.$store.getters[`${this.store}/filter`];
      },
      set() {
        return this.$store.commit(
          `${this.store}/setFilter`,
          this.copyFilter(this.filter),
        );
      },
    },
    isDisabledFilter() {
      const user = auth.getUser();
      if (
        user.roles.find(r => r.id === userRoles.Admin) ||
        user.id === this.selected?.userId
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    filterMenu(value) {
      if (value) {
        this.filter = this.copyFilter(this.storeFilter);
      } else {
        this.filter = null;
        this.selected = null;
        this.isChanged = false;
      }
    },
  },
  methods: {
    apply() {
      if (this.selected && this.isChanged) {
        this.selected = null;
      }
      this.filterMenu = false;
      this.isChanged = false;
      this.storeFilter = this.copyFilter(this.filter);
    },
    copyFilter(source) {
      const copy = {};
      for (const prop in source) {
        if (prop === "id") {
          copy.id = source.id;
          continue;
        }
        copy[prop] = { ...source[prop] };
        if (
          (source[prop].type === "reference" || source[prop].type === "enum") &&
          source[prop].value
        ) {
          copy[prop].value = source[prop].value.map(i => {
            return { ...i };
          });
        }
      }
      return copy;
    },
  },
};
</script>
