import http from "./http.js";

import moment from "moment";
moment.locale("uk");

async function getItems(resource, url) {
  const link = resource === "users" || resource === "shipping-drafts" ? url : `isg-logistics${url}`;

  const response = await http.get(link);

  return {
    totalItems: response.data.totalItems,
    items: response.data.result,
  };
}

export {
  getItems,
};