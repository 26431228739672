<template>
  <v-layout class="mt-4 mx-2 scrollable-block">
    <v-chip
      v-for="(item, index) in chips"
      :key="index"
      small
      close
      :disabled="disabled"
      @update:active="clearItem(item)"
    >
      {{ item.title }}: {{ formatFilterValue(item) }}
    </v-chip>
  </v-layout>
</template>


<script>
export default {
  props: ["store", "disabled"],
  data() {
    return {};
  },
  computed: {
    filter() {
      return this.$store.getters[`${this.store}/filter`];
    },
    chips() {
      const chips = [];
      for (const filterProp in this.filter) {
        const filterItem = this.filter[filterProp];
        if (filterHasValue(filterItem)) {
          chips.push(filterItem);
        }
      }
      return chips;

      function filterHasValue(filterItem) {
        if (
          Object.prototype.hasOwnProperty.call(filterItem, "valueFrom") ||
          Object.prototype.hasOwnProperty.call(filterItem, "valueTo")
        ) {
          return filterItem.valueFrom || filterItem.valueTo;
        }

        if (Object.prototype.hasOwnProperty.call(filterItem, "value")) {
          if (Array.isArray(filterItem.value)) {
            return filterItem.value.length > 0;
          } else {
            return !!filterItem.value;
          }
        }
      }
    },
  },
  methods: {
    clearItem(filterItem) {
      this.$store.dispatch(`${this.store}/changeFilter`, filterItem);
      const parsed = JSON.stringify(this.filter);
      localStorage.setItem("filter", parsed);
    },
    formatFilterValue(item) {
      if (item.type === "date") {
        return `${
          item.valueFrom
            ? "c " + this.$options.filters.date(item.valueFrom)
            : ""
        } ${
          item.valueTo ? "по " + this.$options.filters.date(item.valueTo) : ""
        }`;
      } else if (item.type === "enum") {
        return item.value.map((i) => i.title).join(", ");
      } else if (item.type === "reference") {
        return item.value.map((i) => i.title).join(", ");
      } else if (item.type === "bool") {
        return item.value.title;
      } else if (item.type === "decimal" || item.type === "int") {
        return `${item.valueFrom ? "от " + item.valueFrom : ""} ${
          item.valueTo ? "до " + item.valueTo : ""
        }`;
      } else {
        return item.value;
      }
    },
  },
};
</script>
