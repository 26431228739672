<template>
  <v-tooltip
    :bottom="!isTopPosition && !isRightPosition"
    :top="isTopPosition && !isRightPosition"
    :right="!isTopPosition && isRightPosition"
    :left="isTopPosition && !isRightPosition"
  >
    <template v-slot:activator="{ on: tooltip }">
      <v-btn
        :data-test="dataTest"
        icon
        fab
        :text="updateFlat"
        small
        :color="btnColor || 'grey'"
        class="mt-2"
        v-on="{ ...$listeners, ...tooltip }"
      >
        <v-icon :color="iconColor || ''">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: [
    "icon",
    "tooltip",
    "isFlat",
    "btnColor",
    "iconColor",
    "isTopPosition",
    "dataTest",
    "isRightPosition",
  ],
  computed: {
    updateFlat() {
      if (this.isFlat === false) {
        return this.isFlat;
      } else {
        return true;
      }
    },
  },
};
</script>
